import App from "./App";
import PersistProvider from "./redux/providers/persist-provider";
import { Provider } from "react-redux";
import ThemeProvider from "./redux/providers/theme-provider";
import { createRoot } from "react-dom/client";
import { store } from "./redux/store";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <PersistProvider>
            <ThemeProvider>
                <App />
            </ThemeProvider>
        </PersistProvider>
    </Provider>
);
