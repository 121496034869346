import { Styled2Logo } from './style2';
import { StyledLogo } from './style';
import loginImage from './logoLast.png';
import { useLocation } from 'react-router-dom';

const Logo = () => {
    const location = useLocation();
    console.log({ location: location?.pathname });

    return (
        <>
            <StyledLogo path="/taxation">
                <>
                    <img src={loginImage} alt="Login" />
                </>
            </StyledLogo>
        </>
    );
};

export default Logo;
