import { HashRouter, Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';

import Layout from './layouts';
import Layout02 from './layouts/layout-02';
import Preloader from './components/preloader';

// Classic Pages

const DashboardOne = lazy(() => import('./pages/dashboard-one'));
const DashboardTwo = lazy(() => import('./pages/dashboard-two'));
const DashboardThree = lazy(() => import('./pages/dashboard-three'));
const DashboardFour = lazy(() => import('./pages/dashboard-four'));
const DashboardFive = lazy(() => import('./pages/dashboard-five'));
const DashboardSix = lazy(() => import('./pages/dashboard-six'));
const SignIn = lazy(() => import('./pages/signin'));
const SignUp = lazy(() => import('./pages/signup'));
const VerifyAccount = lazy(() => import('./pages/verify-account'));
const ForgotPassword = lazy(() => import('./pages/forgot-password'));
const ErrorNotFound = lazy(() => import('./pages/error-404'));
const Error500 = lazy(() => import('./pages/error-500'));
const Error503 = lazy(() => import('./pages/error-503'));
const Error505 = lazy(() => import('./pages/error-505'));
const ProfileView = lazy(() => import('./pages/profile-view'));
const Connections = lazy(() => import('./pages/connections'));
const Groups = lazy(() => import('./pages/groups'));
const Events = lazy(() => import('./pages/events'));
const Timeline = lazy(() => import('./pages/timeline'));
const Pricing = lazy(() => import('./pages/pricing'));
const HelpCenter = lazy(() => import('./pages/help-center'));
const Invoice = lazy(() => import('./pages/invoice'));
const Calendar = lazy(() => import('./pages/apps/calendar'));
const Chat = lazy(() => import('./pages/apps/chat'));
const Contacts = lazy(() => import('./pages/apps/contacts'));
const FileManager = lazy(() => import('./pages/apps/file-manager'));
const Mail = lazy(() => import('./pages/apps/mail'));

// Classic Plus Pages
// Classic Plus Pages

const App = () => {
    return (
        <>
            <HashRouter>
                <Suspense fallback={<Preloader />}>
                    <Routes>
                        {/* Classic Routes */}

                        <Route
                            path="/"
                            element={
                                <Layout>
                                    <Outlet />
                                </Layout>
                            }
                        >
                            {/* Dashboard Routes */}
                            <Route path="/taxation" element={<DashboardOne />} />
                            <Route path="/finance" element={<DashboardTwo />} />
                            <Route path="/" element={<SignIn />} />
                            <Route path="/error-404" element={<ErrorNotFound />} />
                        </Route>
                    </Routes>
                </Suspense>
            </HashRouter>
        </>
    );
};

export default App;
