import { ErrorInputStyles, InputStyles, SuccessInputStyles, WarningInputStyles, allowedProps } from '../style';
import { IFeedback, TCustomStyle } from '../types';
import styled, { BorderProps, SpaceProps } from '@doar/shared/styled';

interface IInput extends IFeedback, SpaceProps, BorderProps {
    $width?: string | any[];
    $height?: string | any[];
    $customStyle?: TCustomStyle;
}

export const StyledInput = styled('input').withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => ![...allowedProps].includes(prop) && defaultValidatorFn(prop),
})<IInput>`
    ${InputStyles};
    ${({ $state, $showState, $showErrorOnly }) =>
        $state === 'success' && $showState && !$showErrorOnly && SuccessInputStyles};
    ${({ $state, $showState, $showErrorOnly }) =>
        $state === 'warning' && $showState && !$showErrorOnly && WarningInputStyles};
    ${({ $state, $showState, $showErrorOnly }) =>
        $state === 'error' && $showState && $showErrorOnly && ErrorInputStyles};
`;
