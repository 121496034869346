import Footer from './footer';
import Header from './header';
import { useLocation } from 'react-router-dom';

interface IProps {
    children: React.ReactNode;
    hasSidebar?: boolean;
    hideHeader?: boolean;
    hideFooter?: boolean;
    sidebarLayout?: 1 | 2;
    layout?: 'app';
}

const Layout = ({ children, hasSidebar, hideHeader, hideFooter, sidebarLayout }: IProps) => {
    const location = useLocation();
    console.log({ location: location?.pathname });
    return (
        <>
            {!hideHeader && location?.pathname !== '/' && (
                <Header hasSidebar={hasSidebar} sidebarLayout={sidebarLayout} />
            )}
            {children}
            {!hideFooter && location?.pathname !== '/' && <Footer />}
        </>
    );
};

Layout.defaultProps = {
    hideHeader: false,
    hideFooter: false,
};

export default Layout;
